import React, { ReactNode } from 'react'
import { queryClient } from '_api'
import { QueryClientProvider } from '@tanstack/react-query'
import * as MicroStacks from '@micro-stacks/react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { SnackbarProvider, IconVariant } from 'notistack'
import { FaStar, FaExclamation } from 'react-icons/fa'

const SnackbarIcons: Partial<IconVariant> = {
    default: (
        <div>
            <FaStar />
        </div>
    ),
    error: (
        <div>
            <FaExclamation />
        </div>
    ),
}

export default ({ element }: { element: ReactNode }) => {
    return (
        <QueryClientProvider client={queryClient}>
            <MicroStacks.ClientProvider
                appName="UNUM"
                appIconUrl="https://unum-game.s3.us-east-2.amazonaws.com/v2/logo-unum-gold.png"
            >
                <ParallaxProvider>
                    <SnackbarProvider
                        maxSnack={15}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                        dense={true}
                        autoHideDuration={null}
                        iconVariant={SnackbarIcons}
                        preventDuplicate={true}
                    >
                        {element}
                    </SnackbarProvider>
                </ParallaxProvider>
            </MicroStacks.ClientProvider>
        </QueryClientProvider>
    )
}
