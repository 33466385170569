module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"UNUM Gaame","short_name":"UNUM","start_url":"/","background_color":"#000000","theme_color":"#D8A952","display":"standalone","icon":"static/assets/logo-unum-gold.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"45df069d56238ff89f4d81ced14587ee"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FFR5VPT8X6"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"respectDNT":true,"exclude":["/___graphql"]}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
